<template>
    <div class="portfolio-modal modal fade" id="portfolioModal3" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="container">
            <div class="modal-dialog card cb1">
                <div class="modal-content">
                    <div class="close-modal" data-bs-dismiss="modal"><img src="../../assets/img/close-icon.svg" alt="Close modal" /></div>
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-8">
                                <div class="modal-body">
                                    <!-- Project details-->
                                    <h2 class="text-uppercase">Code Challenge App</h2>
                                    <p class="item-intro text-muted">Consumo de API a travéz de HTTP.</p>
                                    <img class="img-fluid d-block mx-auto" src="../../assets/img/portfolio/3.gif" alt="..." />
                                    <p>Use this area to describe your project. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Est blanditiis dolorem culpa incidunt minus dignissimos deserunt repellat aperiam quasi sunt officia expedita beatae cupiditate, maiores repudiandae, nostrum, reiciendis facere nemo!</p>
                                    <ul class="list-inline">
                                        <li>
                                            <strong>Client:</strong>
                                            Practica Backend (LaunchX - Innovaccion Virtual)
                                        </li>
                                        <li>
                                            <strong>Categoria:</strong>
                                            HTTP | API | Backend | Server | NodeJS | PostgreSQL
                                        </li>
                                    </ul>
                                    <a href="https://github.com/OmarVenturaP/CodeChallengeApp" target="_blank"><button type="button" class="btn btn-outline-light">Repositorio</button></a><br><br>
                                    <button class="btn btn-primary btn-xl text-uppercase" data-bs-dismiss="modal" type="button">
                                        <i class="fas fa-xmark me-1"></i>
                                        Cerrar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PortfolioModal3',
}
</script>

<style>

</style>