<template>
    <section class="page-section bg-dark" id="training">
        <div id="open" class="container">
            <div class="text-center">
                <h1 class="section-heading text-uppercase">CURSOS</h1>
                <h3 class="section-subheading text-muted">La siguiente linea de tiempo marca el proceso de autoaprendizaje que he tenido en los pasados meses.</h3>
            </div>
            <ul class="timeline">
                <li class="timeline-inverted">
                    <div class="timeline-image">
                        <img class="rounded-circle img-fluid" src="../assets/img/about/3.jpg" alt="..." />
                    </div>
                    <div class="timeline-panel">
                    <div class="timeline-heading">
                        <h4>jun 2022 - actual</h4>
                        <h4 class="subheading">Open Bootcamp</h4>
                    </div>
                    <div class="timeline-body">
                        <p id="azure" class="text-muted">
                            Bootcamp de España (FrontEnd - Backend) Enfocado a lo que el mercado laboral necesita. Dando las herrmaientas necesarias para poder aprender tecnologías FullStack.
                        </p>
                    </div>
                    </div>
                </li>
                <li>
                    <a href="#open">
                    <div class="timeline-image">
                        <img class="rounded-circle img-fluid" src="../assets/img/about/7.png" alt="..." />
                    </div>
                    </a>
                    <div class="timeline-panel">
                    <div class="timeline-heading">
                        <h4>junio 2022</h4>
                        <h4 class="subheading">Ciclo Especializado (AZURE)</h4>
                    </div>
                    <div id="one" class="timeline-body">
                        <p class="text-muted">
                            Ciclo enfocado en la nube de Microsoft Azure, durante el cual se trataron aspectos relacionados a la Nube, IoT, IaaS, PaaS, SaaS, Bases de Datos y Seguridad en la Nube. Al final del mismo tuvimos acceso a la certificación AZ-900 de Microsoft Azure.
                        </p>
                    </div>
                    </div>
                    </li>
                <li  class="timeline-inverted">
                    <a href="#azure">
                    <div class="timeline-image">
                        <img class="rounded-circle img-fluid" src="../assets/img/about/2.png" alt="..." />
                    </div>
                    </a>
                    <div class="timeline-panel">
                    <div class="timeline-heading">
                        <h4>abr - oct 2022</h4>
                        <h4 class="subheading">Oracle Next Education (ALURA LATAM)</h4>
                    </div>
                    <div class="timeline-body">
                        <p id="launchx" class="text-muted">
                            Bootcamp de parte de Oracle, relacionado desde el FrontEnd hasta Backend, ampliando la perspectiva del uso de la Nube como apoyo para el desarrollo, además de centrarse en el uso de Metodologías Ágiles.
                        </p>
                    </div>
                    </div>
                </li>
                <li>
                    <a href="#one"><div class="timeline-image"><img class="rounded-circle img-fluid" src="../assets/img/about/3.png" alt="..." /></div></a>
                    <div class="timeline-panel">
                    <div class="timeline-heading">
                        <h4>feb - may 2022</h4>
                        <h4 class="subheading">LaunchX (Innovacción Virtual | Microsoft)</h4>
                    </div>
                    <div class="timeline-body">
                        <p id="learn" class="text-muted">
                            Bootcamp de la iniciativa de parte de Microsoft, bajo el programa LaunchX de Innovacción Virtual, donde se analizaron tecnologías desde el Frontend hasta Backend, generando talleres como el de Visual Thinking y La Empresa Ágile.
                        </p>
                    </div>
                    </div>
                </li>
                <li class="timeline-inverted">
                    <a href="#launchx">
                    <div class="timeline-image">
                        <img class="rounded-circle img-fluid" src="../assets/img/about/4.jpg" alt="..." />
                    </div>
                    </a>
                    <div class="timeline-panel">
                    <div class="timeline-heading">
                        <h4>Diciembre 2021</h4>
                        <h4 class="subheading">Inicia aprendizaje</h4>
                    </div>
                    <div class="timeline-body"><p class="text-muted">Comenzando desde lo basico en HTML y CSS, todo a través de videos en linea y en Youtube absorviendo un poco de lo que sería un gran aprendizaje!</p></div>
                    </div>
                </li>
                <li class="timeline-inverted">
                    <a href="#learn">
                    <div class="timeline-image">
                        <h4>
                            Inicia
                            <br />
                            el
                            <br />
                            Viaje!
                        </h4>
                    </div>
                    </a>
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
export default {
    name: 'VocationalTraining',
}
</script>

<style>

</style>