<template>
    <div class="portfolio-modal modal fade " id="portfolioModal1" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="container">
            <div class="modal-dialog card cb1">
                <div class="modal-content">
                    <div class="close-modal" data-bs-dismiss="modal"><img src="../../assets/img/close-icon.svg" alt="Close modal" /></div>
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-8">
                                <div class="modal-body">
                                    <!-- Project details-->
                                    <h2 class="text-uppercase">ABOGABOT</h2>
                                    <p class="item-intro text-muted">
                                        Digitalizar los despachos de abogados.
                                    </p>
                                    <img class="img-fluid d-block mx-auto" src="../../assets/img/portfolio/1.png" alt="..." />
                                    <p>
                                        Desde la toma de requerimientos hasta el diseño de Interfaz de usuario, este proyecto se enfocaba en recabar información de un posible prospecto de desarrollo, teniendo en cuenta el público objetivo, Buyer persona, Experiencua e Interfáz de Usuario (UX/UI) usando Figma!
                                    </p>
                                    <ul class="list-inline">
                                        <li>
                                            <strong>Cliente:</strong>
                                            Practica FrontEnd (LaunchX - Innovaccion Virtual)
                                        </li>
                                        <li>
                                            <strong>Categoria:</strong>
                                            Diseño de Interfaz de Usuario | UX/UI
                                        </li>
                                    </ul>
                                    <a href="https://github.com/OmarVenturaP/Practica-Frontend-Sem1" target="_blank"><button type="button" class="btn btn-outline-light">Repositorio</button></a>
                                    <a href="https://www.figma.com/file/cfo2idycNrsv3vD3PdD9cH/Abogabot-App?node-id=0%3A1" target="_blank"><button type="button" class="btn btn-outline-light">Figma</button></a><br><br>
                                    <button class="btn btn-primary btn-xl text-uppercase" data-bs-dismiss="modal" type="button">
                                        <i class="fas fa-xmark me-1"></i>
                                        Cerrar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PortfolioModal1',
}
</script>

<style>

</style>