<template>
    <footer class="footer py-4 bg-black">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-4 text-lg-start">Copyright &copy; Omar Ventura 2022</div>
                <div class="col-lg-4 my-3 my-lg-0">
                    <a class="btn btn-dark btn-social mx-2" href="https://github.com/OmarVenturaP" aria-label="GitHub" target="_blank"><i class="fab fa-github"></i></a>
                    <a class="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/in/omarventurap/" aria-label="LinkedIn" target="_blank"><i class="fab fa-linkedin-in"></i></a>
                </div>
                <div class="col-lg-4 text-lg-end">
                    <a class="link-dark text-decoration-none me-3" href="#!">Politica de Privacidad</a>
                    <a class="link-dark text-decoration-none" href="#!">Terminos y Condiciones</a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'FooterPage',
}
</script>

<style>

</style>