<template>
    <div class="portfolio-modal modal fade" id="portfolioModal6" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="container">
            <div class="modal-dialog card cb1">
                <div class="modal-content">
                    <div class="close-modal" data-bs-dismiss="modal"><img src="../../assets/img/close-icon.svg" alt="Close modal" /></div>
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-8">
                                <div class="modal-body">
                                    <!-- Project details-->
                                    <h2 class="text-uppercase">Portfolio</h2>
                                    <p class="item-intro text-muted">Diseño de Portfolio de proyectos.</p>
                                    <img class="img-fluid d-block mx-auto" src="../../assets/img/portfolio/7.png" width="350px" alt="..." />
                                    <p>Diseño de portfolio de proyectos, realizado con VueJs, generando componentes y agregandolos en la aplicación principal. Se creo el proyecto y se alojó en <strong>CloudFlare</strong></p>
                                    <ul class="list-inline">
                                        <li>
                                            <strong>Cliente:</strong>
                                            Omar Ventura
                                        </li>
                                        <li>
                                            <strong>Categoria:</strong>
                                            Diseño web | Responsive | VueJs | Skills
                                        </li>
                                    </ul>
                                    <a href="https://github.com/OmarVenturaP/portfolio" target="_blank"><button type="button" class="btn btn-outline-light">Repositorio</button></a>
                                    <a href="https://omarventura.ml" target="_blank"><button type="button" class="btn btn-outline-light">Aplicación Web</button></a><br><br>
                                    <button class="btn btn-primary btn-xl text-uppercase" data-bs-dismiss="modal" type="button">
                                        <i class="fas fa-xmark me-1"></i>
                                        Cerrar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PortfolioModal6',
}
</script>

<style>

</style>