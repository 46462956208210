<template>
    <section class="page-section bg-dark" id="about">
        <div class="container">
            <div class="text-center">
                <h1 class="section-heading text-uppercase">sobre mi</h1>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="team-member">
                        <img class="mx-auto rounded-circle" src="../assets/img/team/1.png" alt="..." />
                        <h4>Omar Ventura</h4>
                        <p class="text-muted"></p>
                        <a class="btn btn-dark btn-social mx-2" href="https://github.com/OmarVenturaP" aria-label="Omar Ventura GitHub Profile" target="_blank"><i class="fab fa-github"></i></a>
                        <a class="btn btn-dark btn-social mx-2" href="https://wa.link/r5vy32" aria-label="Omar Ventura Whatsapp" target="_blank"><i class="fab fa-whatsapp"></i></a>
                        <a class="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/in/omarventurap/" aria-label="Omar Ventura LinkedIn Profile" target="_blank"><i class="fab fa-linkedin-in"></i></a>
                        <a class="btn btn-dark btn-social mx-2" href="mailto:reparaciones.tonala@hotmail..com?Subject=Información%sobre" aria-label="Omar Ventura mail Profile" target="_blank"><i class="fab fa-solid fa-envelope"></i></a>
                        <a class="btn btn-dark btn-social mx-2" href="https://drive.google.com/file/d/1aDCQ_DA3aZ6xMD36L8C4gBqfS0fRwXl5/view?usp=sharing" aria-label="Omar Ventura CV" target="_blank"><i class="fab fa-solid fa-file-pdf"></i></a>
                    </div>
                </div>
                <div class="col-lg-6 text-center"><br>
                    <h4>Soft Skills</h4>
                    <p class="text-muted"></p>
                    <a class="btn btn-dark btn-social1 mx-1" data-bs-toggle="modal" href="#softskill1"><i class="fab fa-solid fa-people-group"></i></a>
                    <a class="btn btn-dark btn-social1 mx-1" data-bs-toggle="modal" href="#softskill2"><i class="fab fa-solid fa-users-gear"></i></a>
                    <a class="btn btn-dark btn-social1 mx-1" data-bs-toggle="modal" href="#softskill3"><i class="fab fa-solid fa-person-chalkboard"></i></a>
                    <a class="btn btn-dark btn-social1 mx-1" data-bs-toggle="modal" href="#softskill4"><i class="fab fa-solid fa-people-carry-box"></i></a>
                    <a class="btn btn-dark btn-social1 mx-1" data-bs-toggle="modal" href="#softskill11"><i class="fab fa-solid fa-comments"></i></a>
                    <a class="btn btn-dark btn-social1 mx-1" data-bs-toggle="modal" href="#softskill12"><i class="fab fa-solid fa-ear-listen"></i></a>
                    <a class="btn btn-dark btn-social1 mx-1" data-bs-toggle="modal" href="#softskill5"><i class="fab fa-solid fa-language"></i></a>
                    <a class="btn btn-dark btn-social1 mx-1" data-bs-toggle="modal" href="#softskill6"><i class="fab fa-solid fa-graduation-cap"></i></a>
                    <a class="btn btn-dark btn-social1 mx-1" data-bs-toggle="modal" href="#softskill7"><i class="fab fa-solid fa-user-plus"></i></a>
                    <a class="btn btn-dark btn-social1 mx-1" data-bs-toggle="modal" href="#softskill8"><i class="fab fa-solid fa-book"></i></a>
                    <a class="btn btn-dark btn-social1 mx-1" data-bs-toggle="modal" href="#softskill9"><i class="fab fa-solid fa-kitchen-set"></i></a>
                    <a class="btn btn-dark btn-social1 mx-1" data-bs-toggle="modal" href="#softskill10"><i class="fab fa-solid fa-futbol"></i></a>
                    <a class="btn btn-dark btn-social1 mx-1" data-bs-toggle="modal" href="#softskill13"><i class="fab fa-solid fa-tv"></i></a>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-9 mx-auto text-center">
                    <p class="large text-muted">
                        Profesional autodidacta, con una excelente capacidad de organización y un buen conocimiento de Tecnologías y Servicios Generales, que disfruta trabajando en entornos dinámicos y en contacto con el cliente. Capaz de ofrecer una sólida resolución de posibles incidencias. Con competencias tecnológicas e interpersonales.
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'AboutMe',
}
</script>

<style>

</style>