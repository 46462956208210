<template>
    <div class="portfolio-modal modal fade" id="portfolioModal5" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="container">
            <div class="modal-dialog card cb1">
                <div class="modal-content">
                    <div class="close-modal" data-bs-dismiss="modal"><img src="../../assets/img/close-icon.svg" alt="Close modal" /></div>
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-8">
                                <div class="modal-body">
                                    <!-- Project details-->
                                    <h2 class="text-uppercase">Traviu</h2>
                                    <p class="item-intro text-muted">Diseño de aplicación para viajes.</p>
                                    <img class="img-fluid d-block mx-auto" src="../../assets/img/portfolio/4.png" alt="..." />
                                    <p>En esta ocasión se me dieron algunos requisitos para un diseño de una aplicación mobile, en el cual usando FIGMA, pude realizar el diseño tanto modo normal como modo nocturno para dicha solicitud. Quedando el resultado que se muestra.</p>
                                    <ul class="list-inline">
                                        <li>
                                            <strong>Cliente:</strong>
                                            Alegra
                                        </li>
                                        <li>
                                            <strong>Categoria:</strong>
                                            UI | Diseño web | Diseño mobile | Figma
                                        </li>
                                    </ul>
                                    <a href="https://www.figma.com/file/UH9mLKn0UghETWh06elOtG/traviu-app?node-id=0%3A1" target="_blank"><button type="button" class="btn btn-outline-light">Figma</button></a><br><br>
                                    <button class="btn btn-primary btn-xl text-uppercase" data-bs-dismiss="modal" type="button">
                                        <i class="fas fa-xmark me-1"></i>
                                        Cerrar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PortfolioModal5',
}
</script>

<style>

</style>