<template>
    <header class="masthead" id="page-top">
        <div class="container">
            <div class="masthead-subheading">Software Developer Jr!</div>
            <div class="masthead-heading text-uppercase">Omar Ventura</div>
            <a class="btn btn-primary btn-xl text-uppercase" href="#services">Tecnologías</a>
        </div>
    </header>
</template>

<script>
export default {
    name: 'HeroSection',
}
</script>

<style>

</style>