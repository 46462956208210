<template>
<section class="page-section bg-dark" id="tech">
    <div class="container">
        <div class="text-center">
            <h1 class="section-heading text-uppercase">skills</h1>
            <h3 class="section-subheading text-muted">A lo largo de mi autoaprendizaje he trabajado con algunas de estas tecnologías:</h3>
        </div>
        <div class="row text-center">
            <div class="col-md-2">
                <span class="fa-stack fa-2x">
                    <i class="fas fa-circle fa-stack-2x text-primary"></i>
                    <i class="fas fa-brands fa-html5 fa-stack-1x fa-inverse"></i>
                </span>
                <h6 class="my-2">HTML</h6>
            </div>
            <div class="col-md-2">
                <span class="fa-stack fa-2x">
                    <i class="fas fa-circle fa-stack-2x text-primary"></i>
                    <i class="fas fa-brands fa-css3-alt fa-stack-1x fa-inverse"></i>
                </span>
                <h6 class="my-2">CSS</h6>
            </div>
            <div class="col-md-2">
                <span class="fa-stack fa-2x">
                    <i class="fas fa-circle fa-stack-2x text-primary"></i>
                    <i class="fas fa-brands fa-js-square fa-stack-1x fa-inverse"></i>
                </span>
                <h6 class="my-2">JavaScript</h6>
            </div>
            <div class="col-md-2">
                <span class="fa-stack fa-2x">
                    <i class="fas fa-circle fa-stack-2x text-primary"></i>
                    <i class="fas fa-brands fa-vuejs fa-stack-1x fa-inverse"></i>
                </span>
                <h6 class="my-2">VueJs</h6>
            </div>
            <div class="col-md-2">
                <span class="fa-stack fa-2x">
                    <i class="fas fa-circle fa-stack-2x text-primary"></i>
                    <i class="fas fa-brands fa-react fa-stack-1x fa-inverse"></i>
                </span>
                <h6 class="my-2">ReactJs</h6>
            </div>
            <div class="col-md-2">
                <span class="fa-stack fa-2x">
                    <i class="fas fa-circle fa-stack-2x text-primary"></i>
                    <i class="fas fa-brands fa-node-js fa-stack-1x fa-inverse"></i>
                </span>
                <h6 class="my-2">NodeJs</h6>
                <h6 class="my-2">Express</h6>
            </div>
                <div class="col-md-2">
                    <span class="fa-stack fa-2x">
                        <i class="fas fa-circle fa-stack-2x text-primary"></i>
                        <i class="fas fa-solid fa-database fa-stack-1x fa-inverse"></i>
                    </span>
                    <h6 class="my-2">PostgreSQL</h6>
                    <h6 class="my-2">MySQL</h6>
                </div>
                <div class="col-md-2">
                    <span class="fa-stack fa-2x">
                        <i class="fas fa-circle fa-stack-2x text-primary"></i>
                        <i class="fas fa-brands fa-git-alt fa-stack-1x fa-inverse"></i>
                    </span>
                    <h6 class="my-2">Git</h6>
                </div>
                <div class="col-md-2">
                    <span class="fa-stack fa-2x">
                        <i class="fas fa-circle fa-stack-2x text-primary"></i>
                        <i class="fas fa-brands fa-github fa-stack-1x fa-inverse"></i>
                    </span>
                    <h6 class="my-2">GitHub</h6>
                </div>
                <div class="col-md-2">
                    <span class="fa-stack fa-2x">
                        <i class="fas fa-circle fa-stack-2x text-primary"></i>
                        <i class="fas fa-brands fa-npm fa-stack-1x fa-inverse"></i>
                    </span>
                <h6 class="my-2">npm</h6>
                </div>
                <div class="col-md-2">
                    <span class="fa-stack fa-2x">
                        <i class="fas fa-circle fa-stack-2x text-primary"></i>
                        <i class="fas fa-solid fa-flask-vial fa-stack-1x fa-inverse"></i>
                    </span>
                    <h6 class="my-2">TDD</h6>
                </div>
                <div class="col-md-2">
                    <span class="fa-stack fa-2x">
                        <i class="fas fa-circle fa-stack-2x text-primary"></i>
                        <i class="fas fa-brands fa-bootstrap fa-stack-1x fa-inverse"></i>
                    </span>
                    <h6 class="my-2">Bootstrap</h6>
                </div>
                <div class="col-md-2">
                    <span class="fa-stack fa-2x">
                        <i class="fas fa-circle fa-stack-2x text-primary"></i>
                        <i class="fas fa-brands fa-figma fa-stack-1x fa-inverse"></i>
                    </span>
                    <h6 class="my-2">Figma</h6>
                </div>
                <div class="col-md-2">
                    <span class="fa-stack fa-2x">
                        <i class="fas fa-circle fa-stack-2x text-primary"></i>
                        <i class="fas fa-brands fa-trello fa-stack-1x fa-inverse"></i>
                        </span>
                    <h6 class="my-2">Trello</h6>
                </div>
                <div class="col-md-2">
                    <span class="fa-stack fa-2x">
                        <i class="fas fa-circle fa-stack-2x text-primary"></i>
                        <i class="fas fa-solid fa-cloud fa-stack-1x fa-inverse"></i>
                    </span>
                    <h6 class="my-2">Azure</h6>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'HardSkills'
}
</script>

<style>

</style>