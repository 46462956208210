<template>
    <!-- Soft skills 1-->
        <div class="portfolio-modal modal fade" id="softskill1" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="containerCard">
                <div class="modal-dialog card cb1">
                    <div class="modal-content">
                        <div class="row justify-content-center">
                            <div class="col-lg-12">
                                <h1>
                                    <i class="fab fa-solid fa-people-group"></i>
                                </h1><br>
                                <h3>Trabajo en Equipo</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!-- Soft skills 2-->
        <div class="portfolio-modal modal fade" id="softskill2" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="containerCard">
                <div class="modal-dialog card cb1">
                    <div class="modal-content">
                        <div class="row justify-content-center">
                            <div class="col-lg-12">
                                <h1>
                                    <i class="fab fa-solid fa-users-gear"></i>
                                </h1><br>
                                <h3>Liderazgo</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!-- Soft skills 3-->
        <div class="portfolio-modal modal fade" id="softskill3" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="containerCard">
                <div class="modal-dialog card cb1">
                    <div class="modal-content">
                        <div class="row justify-content-center">
                            <div class="col-lg-12">
                                <h1>
                                    <i class="fab fa-solid fa-person-chalkboard"></i>
                                </h1><br>
                                <h3>Organización</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!-- Soft skills 4-->
        <div class="portfolio-modal modal fade" id="softskill4" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="containerCard">
                <div class="modal-dialog card cb1">
                    <div class="modal-content">
                        <div class="row justify-content-center">
                            <div class="col-lg-12">
                                <h1>
                                    <i class="fab fa-solid fa-people-carry-box"></i>
                                </h1><br>
                                <h3>Apoyo mutuo</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!-- Soft skills 5-->
        <div class="portfolio-modal modal fade" id="softskill5" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="containerCard">
                <div class="modal-dialog card cb1">
                    <div class="modal-content">
                        <div class="row justify-content-center">
                            <div class="col-lg-12">
                                <h1>
                                    <i class="fab fa-solid fa-language"></i>
                                </h1><br>
                                <h4>Español: Nativo</h4>
                                <h4>Inglés: Basico-Intermedio (Técnico)</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!-- Soft skills 6-->
        <div class="portfolio-modal modal fade" id="softskill6" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="containerCard">
                <div class="modal-dialog card cb1">
                    <div class="modal-content">
                        <div class="row justify-content-center">
                            <div class="col-lg-12">
                                <h1>
                                    <i class="fab fa-solid fa-graduation-cap"></i>
                                </h1><br>
                                <h3>Autodidacta</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!-- Soft skills 7-->
        <div class="portfolio-modal modal fade" id="softskill7" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="containerCard">
                <div class="modal-dialog card cb1">
                    <div class="modal-content">
                        <div class="row justify-content-center">
                            <div class="col-lg-12">
                                <h1>
                                    <i class="fab fa-solid fa-user-plus"></i>
                                </h1><br>
                                <h3>Amistoso</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!-- softskill8-->
        <div class="portfolio-modal modal fade" id="softskill8" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="containerCard">
                <div class="modal-dialog card cb1">
                    <div class="modal-content">
                        <div class="row justify-content-center">
                            <div class="col-lg-12">
                                <h1>
                                    <i class="fab fa-solid fa-book"></i>
                                </h1><br>
                                <h3>Gusto por la lectura</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!-- softskill9-->
        <div class="portfolio-modal modal fade" id="softskill9" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="containerCard">
                <div class="modal-dialog card cb1">
                    <div class="modal-content">
                        <div class="row justify-content-center">
                            <div class="col-lg-12">
                                <h1>
                                    <i class="fab fa-solid fa-kitchen-set"></i>
                                </h1><br>
                                <h3>Gusto por la cocina</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!-- softskill10-->
        <div class="portfolio-modal modal fade" id="softskill10" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="containerCard">
                <div class="modal-dialog card cb1">
                    <div class="modal-content">
                        <div class="row justify-content-center">
                            <div class="col-lg-12">
                                <h1>
                                    <i class="fab fa-solid fa-futbol"></i>
                                </h1><br>
                                <h3>Gusto por los Deportes</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!-- softskill11-->
        <div class="portfolio-modal modal fade" id="softskill11" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="containerCard">
                <div class="modal-dialog card cb1">
                    <div class="modal-content">
                        <div class="row justify-content-center">
                            <div class="col-lg-12">
                                <h1>
                                    <i class="fab fa-solid fa-comments"></i>
                                </h1><br>
                                <h3>Sociable</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!-- softskill12-->
        <div class="portfolio-modal modal fade" id="softskill12" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="containerCard">
                <div class="modal-dialog card cb1">
                    <div class="modal-content">
                        <div class="row justify-content-center">
                            <div class="col-lg-12">
                                <h1>
                                    <i class="fab fa-solid fa-ear-listen"></i>
                                </h1><br>
                                <h3>Empático</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!-- softskill13-->
        <div class="portfolio-modal modal fade" id="softskill13" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="containerCard">
                <div class="modal-dialog card cb1">
                    <div class="modal-content">
                        <div class="row justify-content-center">
                            <div class="col-lg-12">
                                <h1>
                                    <i class="fab fa-solid fa-tv"></i>
                                </h1><br>
                                <h3>Gusto por el entretenimiento</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
export default {
    name: 'SoftSkills',
}
</script>

<style>

</style>