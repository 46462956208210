<template>
    <section class="page-section bg-dark" id="certified">
        <div class="container">
            <div class="text-center">
                <h1 class="section-heading text-uppercase">LOGROS</h1>
            </div>
            <div class="row">
                <div class="col-lg-4 text-center">
                    <div class="card  m-2 cb1 text-center">
                        <div class="text-center"><br>
                            <img src="../assets/img/portfolio/azure.png" class="card-img-top" alt="...">
                        </div>
                        <div class="card-body">
                            <h5 class="card-title mb-4">AZ-900</h5>
                            <a href="https://drive.google.com/file/d/1br8_nIQMQyVhRSSgf9AOWqdQZDsqTWCW/view?usp=sharing" class="btn btn-dark btn-primary" target="_blank">Certificación</a><br><br>
                            <a href="https://www.credly.com/badges/07ccaf21-22b2-4e5d-9619-103d486d9ed7?source=linked_in_profile" class="btn btn-dark btn-primary" target="_blank">Verificar</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="card  m-2 cb1 text-center">
                        <div class="text-center"><br><br>
                            <img src="../assets/img/logos/alura.svg" class="card-img1" alt="...">
                        </div>
                        <div class="card-body"><br><br>
                            <h5 class="card-title mb-4">Fundamentos de Agilidad:</h5>
                            <h5 class="card-title mb-4">Primeros pasos para la transformación ágil</h5><br>
                            <a href="https://app.aluracursos.com/certificate/193bd426-0193-44cb-ac9b-5556a65a5d54" class="btn btn-dark btn-primary" target="_blank">Verificar</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="card  m-2 cb1 text-center">
                        <div class="text-center"><br>
                            <img src="../assets/img/portfolio/telmex.png" class="card-img-top" alt="...">
                        </div>
                        <div class="card-body">
                            <p></p>
                            <h5 class="card-title mb-4">Diplomado Técnico en Sistemas Informáticos</h5><br>
                            <a href="https://capacitateparaelempleo.org/verifica_diplomado/59XR2CL0K/" class="btn btn-dark btn-primary" target="_blank">Verificar</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="py-5 bg-dark">
            <div class="team-member">
                <h4></h4>
            </div>
            <div class="container">
            </div>
        </div>
</template>

<script>
export default {
    name: 'MyCertifications',
}
</script>

<style>

</style>